@import url('https://fonts.googleapis.com/css?family=Lato:100,200,300,400,700&subset=latin-ext&display=swap');
@import url("https://use.typekit.net/uxx4ebk.css");


body {
  --main-font:  'Lato', sans-serif;
  --logo-font-main: 'hightower-text', serif;
  --logo-font-sub: 'aviano-sans', sans-serif;
  --forest-green: #646F4B;
  --forest-green-light: rgba(100,111,75,.5);
  --gray-dark: #839D9A;
  --gray-light: #BFD2BF;
  --blue-light: #7BB2D9;
  --purple-clouds: #9faecb;
}

#body-container {
  font-family: var(--main-font);
}

.link, .link a {
  width: fit-content;
  color: var(--forest-green) !important;
  transition: .3s !important;
}

.link:hover, .link a:hover {
  transform: scale(1.1);
  text-decoration: none;
}

#site-logo .text {
  color: var(--forest-green);
  font-size: 20px;
}

.page-container {
  background-color: var(--forest-green);
  padding:20px;
  min-height: 92.8vh;
}

.page-overlay {
  background-color: white;
  border-radius: 10px;
  width: 95%;
  margin:auto;
  padding:25px;
  color: var(--forest-green);
}

.page-overlay.mobile {
  padding: 5px;
}