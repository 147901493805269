@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400,700&subset=latin-ext&display=swap);
@import url(https://use.typekit.net/uxx4ebk.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#nav-bar {
    /* position: fixed; */
    background-color: white;
    width: 100%;
    z-index: 1000;
    /* box-shadow: var(--forest-green) 1px 1px 10px; */
}

#nav-bar .btn-group {
    display: inline-flex;
    justify-content: space-evenly;
    text-align: right;
}

#sideNavBackground {
    transition: background-color .5s;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}

#sideNavBackground.dark {
    z-index: 4;
    background-color: rgba(0,0,0,.5);
}

#sideNavBackground.light {
    z-index: -4;
    background-color: transparent;
}

#sideNav {
    height: 100vh;
    width: 45%;
    background-color: white;
    color: white;
    z-index: 5;
    transition: all .5s;
    position: fixed;
    top: 0;
}

#sideNav button {
    background-color: transparent;
    border: none;   
}

#sideNav button a {
    color: var(--forest-green);
}

#sideNav.open {
    right: 0;
}

#sideNav.closed {
    right: -45%;
}

.link .is-active {
    background-color: var(--forest-green);
    color: white !important;
    padding: 19px;
}

.link .is-active.home {
    background-color: white;
    color: var(--forest-green) !important;
}

.link a {
    text-decoration: none;
}

.menuIcon button {
    background-color: transparent;
    border: none;
    color: var(--forest-green);
    z-index: 5;
}
/* .img-container img.background, .img-container .cover{
    width: 100%;
    height: 100vh;
    object-fit: cover;
} */

/* .img-container img.background {
    filter: blur(2px);
} */

.videoBg {
    position: fixed;
    right: 0;
    min-width: 100%;
    min-height: 100%;
}

.videoBg.mobile {
    top: 0;
    z-index: -1;
}

#home {
    text-align: center;
    background: rgba(255,255,255,.25);
    position: fixed;
    height: 100%;
    width: 100%;
}

#home img {
    margin-top: 50px;
}

#home.mobile {
    width: 100%;
    margin-top: 25%;
    margin-left: auto;
}

#home .front-btns {
    display: inline-flex;
    justify-content: space-evenly;
    width: 100%;
}

#home .front-btns.mobile {
    flex-direction: column;
}

.front-btns a {
    color: var(--forest-green);
    border-bottom: 1px solid var(--forest-green-light);
    font-size: 25px;
    margin: 10px;
    padding: 3px 15px;
    font-weight: 300;
    transition: .5s;
    text-decoration: none !important;
}

.front-btns a:hover {
    color: var(--forest-green-light);
    text-decoration: none;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
blockquote {
    margin: 0 auto;
    padding: .5em;
    border-left: 2px solid var(--forest-green);
  }
  blockquote:before {
    display: none;
  }
  blockquote:not(:first-of-type) {
    margin-top: .5em;
  }
  blockquote p {
    color: var(--forest-green);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    /* font-family: 'PT Serif', Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif; */
  }

#aboutme img {
    border-radius: 10px
}

#aboutme h2.name {
    font-size: 50px;
}

#aboutme h2 {
    font-weight: 300;
    padding: 10px 0;
    text-transform: uppercase;
    border-bottom: 1px solid var(--gray-light);
}

.training h2 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#aboutme .description p, .training li, #aboutme .creds{
    font-size: 18px;
}

.creds {
    list-style: none;
}

.creds a {
    color: var(--forest-green);
}


.zoomBtns {
    position: relative;
    bottom: 70px;
}
.zoomBtns button {
    background-color: grey;
    font-weight: bold;
    font-size: 24px;
    color: white;
    width: 40px;
    height: 40px;
    border: 1px solid 
}

.form-group {
    margin-bottom: 20px;
}

.map p {
    margin-bottom: 1px;
}

.btn-primary {
    border: none !important;
    background-color: var(--forest-green) !important;
}

.btn-primary:disabled {
    background-color: var(--forest-green-light) !important;
}
body {
  --main-font:  'Lato', sans-serif;
  --logo-font-main: 'hightower-text', serif;
  --logo-font-sub: 'aviano-sans', sans-serif;
  --forest-green: #646F4B;
  --forest-green-light: rgba(100,111,75,.5);
  --gray-dark: #839D9A;
  --gray-light: #BFD2BF;
  --blue-light: #7BB2D9;
  --purple-clouds: #9faecb;
}

#body-container {
  font-family: var(--main-font);
}

.link, .link a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--forest-green) !important;
  transition: .3s !important;
}

.link:hover, .link a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  text-decoration: none;
}

#site-logo .text {
  color: var(--forest-green);
  font-size: 20px;
}

.page-container {
  background-color: var(--forest-green);
  padding:20px;
  min-height: 92.8vh;
}

.page-overlay {
  background-color: white;
  border-radius: 10px;
  width: 95%;
  margin:auto;
  padding:25px;
  color: var(--forest-green);
}

.page-overlay.mobile {
  padding: 5px;
}
