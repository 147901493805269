/* .img-container img.background, .img-container .cover{
    width: 100%;
    height: 100vh;
    object-fit: cover;
} */

/* .img-container img.background {
    filter: blur(2px);
} */

.videoBg {
    position: fixed;
    right: 0;
    min-width: 100%;
    min-height: 100%;
}

.videoBg.mobile {
    top: 0;
    z-index: -1;
}

#home {
    text-align: center;
    background: rgba(255,255,255,.25);
    position: fixed;
    height: 100%;
    width: 100%;
}

#home img {
    margin-top: 50px;
}

#home.mobile {
    width: 100%;
    margin-top: 25%;
    margin-left: auto;
}

#home .front-btns {
    display: inline-flex;
    justify-content: space-evenly;
    width: 100%;
}

#home .front-btns.mobile {
    flex-direction: column;
}

.front-btns a {
    color: var(--forest-green);
    border-bottom: 1px solid var(--forest-green-light);
    font-size: 25px;
    margin: 10px;
    padding: 3px 15px;
    font-weight: 300;
    transition: .5s;
    text-decoration: none !important;
}

.front-btns a:hover {
    color: var(--forest-green-light);
    text-decoration: none;
    transform: scale(1.1);
}