#nav-bar {
    /* position: fixed; */
    background-color: white;
    width: 100%;
    z-index: 1000;
    /* box-shadow: var(--forest-green) 1px 1px 10px; */
}

#nav-bar .btn-group {
    display: inline-flex;
    justify-content: space-evenly;
    text-align: right;
}

#sideNavBackground {
    transition: background-color .5s;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}

#sideNavBackground.dark {
    z-index: 4;
    background-color: rgba(0,0,0,.5);
}

#sideNavBackground.light {
    z-index: -4;
    background-color: transparent;
}

#sideNav {
    height: 100vh;
    width: 45%;
    background-color: white;
    color: white;
    z-index: 5;
    transition: all .5s;
    position: fixed;
    top: 0;
}

#sideNav button {
    background-color: transparent;
    border: none;   
}

#sideNav button a {
    color: var(--forest-green);
}

#sideNav.open {
    right: 0;
}

#sideNav.closed {
    right: -45%;
}

.link .is-active {
    background-color: var(--forest-green);
    color: white !important;
    padding: 19px;
}

.link .is-active.home {
    background-color: white;
    color: var(--forest-green) !important;
}

.link a {
    text-decoration: none;
}

.menuIcon button {
    background-color: transparent;
    border: none;
    color: var(--forest-green);
    z-index: 5;
}