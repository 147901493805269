blockquote {
    margin: 0 auto;
    padding: .5em;
    border-left: 2px solid var(--forest-green);
  }
  blockquote:before {
    display: none;
  }
  blockquote:not(:first-of-type) {
    margin-top: .5em;
  }
  blockquote p {
    color: var(--forest-green);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    /* font-family: 'PT Serif', Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif; */
  }