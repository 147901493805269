.zoomBtns {
    position: relative;
    bottom: 70px;
}
.zoomBtns button {
    background-color: grey;
    font-weight: bold;
    font-size: 24px;
    color: white;
    width: 40px;
    height: 40px;
    border: 1px solid 
}

.form-group {
    margin-bottom: 20px;
}

.map p {
    margin-bottom: 1px;
}

.btn-primary {
    border: none !important;
    background-color: var(--forest-green) !important;
}

.btn-primary:disabled {
    background-color: var(--forest-green-light) !important;
}