
#aboutme img {
    border-radius: 10px
}

#aboutme h2.name {
    font-size: 50px;
}

#aboutme h2 {
    font-weight: 300;
    padding: 10px 0;
    text-transform: uppercase;
    border-bottom: 1px solid var(--gray-light);
}

.training h2 {
    width: fit-content;
}

#aboutme .description p, .training li, #aboutme .creds{
    font-size: 18px;
}

.creds {
    list-style: none;
}

.creds a {
    color: var(--forest-green);
}

